.<template>
  <div>
    <Header />

    <section class="section1">
      <div class="about-us-box">
        <div>关于我们</div>
        <div>ABOUT US</div>
      </div>
    </section>

    <section class="section2">
      <div class="container container-space-y-m">
        <div class="d-flex">
          <div class="flex-grow-1">
            <img src="@/assets/images/illustration_3.png"
                 alt="">
          </div>
          <div class="flex-shrink-0">
            <device-browser>
              <div class="introduction">
                <div class="h1">关于我们<small>ABOUT US</small></div>
                <p>极汇互动（广东）信息科技有限公司-----企业一站式短信供应商</p>

                <p>极汇互动自成立以来一直专注于移动信息服务领域，是国内了领先的企业信息化鼓舞提供商，与电信运营商合作提供行业移动信息服务和个人移动信息服务，是中国移动SI（服务集成商）的战略合作伙伴，具备集成型SI国内三大运营商的全业务代理资质。</p>
                <p>极汇互动现与京东等各大平台都有紧密的合作，一直致力于企业信息化服务解决方案、企业系统集成的解决方案和短信息等服务方案。企业的个性化要求让多元的合作商产品和解决方案应运而生。</p>
                <p>目前为止极汇互动（广东）信息科技有限公司已为多家企业信息系统提供产品和技术服务，受到客户的一致赞誉。极汇互动不断加强信息技术商家合作深入了解各用户产品与需求，针对客户不同的需求提供最适合的解决方案。客户不仅能从极汇互动得到符合专业需求的产品和服务。极汇互动（广东）信息科技有限公司不仅依据自身的实力和高质量的服务赢得客户的信赖，更拥有众多的战略合作伙伴提供强有力的支持。</p>
                <p>极汇互动（广东）信息科技有限公司依靠强大的技术支持和良好的售后服务为客户提供全方案的服务需求方案。</p>
                <p>服务热线：020-86574220</p>
                <p>企业邮箱：txy@x046.com</p>
              </div>
            </device-browser>
          </div>
        </div>
      </div>
    </section>

    <section class="section3">
      <div class="container container-space-y-l">
        <div class="d-flex">
          <div>
            <baidu-map class="baidu-map-view"
                       :scroll-wheel-zoom="true"
                       @ready="mapReady">
              <!-- bm-marker 就是标注点 定位在point的经纬度上 跳动的动画 -->
              <bm-marker :position="point"
                         animation="BMAP_ANIMATION_BOUNCE"
                         @click="showInfoWindow = true">
              </bm-marker>
              <bm-info-window title="极汇互动(广东)信息科技有限公司"
                              :width="350"
                              :position="point"
                              :show="showInfoWindow"
                              @close="showInfoWindow = false">
                <p>地址：广东省广州市黄埔区联和街道广汕3路31号</p>
                <p>联系电话：020-86574220</p>
              </bm-info-window>
            </baidu-map>
          </div>
          <div class="ml-5">
            <div class="h4">办公地址/Office Address</div>
            <div class="h6">广东省广州市黄埔区联和街道广汕3路31号</div>
            <div class="h4">联系方式/Phone</div>
            <div class="h6">服务热线：020-86574220</div>
          </div>
        </div>
      </div>
    </section>

    <BannerRegistration />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
import DeviceBrowser from '@/components/device-browser'
import BannerRegistration from '@/components/banner-registration'
export default {
  name: 'ContactUs',
  components: {
    Header,
    Footer,
    DeviceBrowser,
    BannerRegistration
  },
  data () {
    return {
      point: {},
      showInfoWindow: true // 显示信息窗口
    }
  },

  methods: {
    mapReady ({ BMap, map }) {
      // 选择一个经纬度作为中心点
      this.point = new BMap.Point(113.44308, 23.194689)
      map.centerAndZoom(this.point, 17)
    }

  }
}
</script>

<style lang="scss" scoped>
.section1 {
  width: 100%;
  height: 450px;
  background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.1) 50%
    ),
    url("~@/assets/images/banner02.jpg") no-repeat center;
  background-size: 100%;

  .about-us-box {
    font-size: 30px;
    font-weight: 700;
    color: #fff;
    position: absolute;
    left: 16%;
    top: 200px;
  }
}

.section2 {
  .introduction {
    padding: 30px;

    .h1 {
      font-weight: 700;
      margin-bottom: 30px;

      small {
        font-size: 24px;
        margin-left: 12px;
      }
    }

    p {
      text-indent: 2rem;
      margin-bottom: 1rem;
    }
  }
}

.section3 {
  background: #f8f9fa;

  .baidu-map-view {
    width: 600px;
    height: 350px;
    box-shadow: 0 0 27px 0 rgba(0, 0, 0, 0.1);
  }
}
</style>
