<template>
  <figure class="device-browser">
    <div class="device-browser-header">
      <div class="device-browser-header-btn-list">
        <span class="device-browser-header-btn-list-btn"></span>
        <span class="device-browser-header-btn-list-btn"></span>
        <span class="device-browser-header-btn-list-btn"></span>
      </div>
      <div class="device-browser-header-browser-bar">{{urlText}}</div>
    </div>

    <div class="device-browser-frame">
      <slot class="device-browser-img">

      </slot>
    </div>

    <div :class="`device-browser-shadow bg-${shadow} ${shadowClass}`"
         :style="shadowStyle"
         v-if="shadow && shadow !== 'never'"></div>
  </figure>
</template>

<script>
export default {
  name: 'DeviceBrowser',
  props: {
    urlText: {
      type: String,
      default: 'www.xxx.com'
    },
    shadow: {
      type: String,
      default: '1'
    },
    shadowClass: String,
    shadowStyle: Object
  }
}

</script>

<style lang="scss" scoped>
.device-browser {
  position: relative;
  display: block;
  max-width: 100%;
  width: 50rem;
  height: auto;
  margin: 0 auto;
  z-index: 1;
}

.device-browser-header {
  position: relative;
  display: flex;
  max-width: 50rem;
  background: #fff;
  border-bottom: 0.0625rem solid #f1f3f8;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  padding: 0.5rem 6.5rem;
}

.device-browser-header-btn-list {
  display: flex;
  grid-gap: 0.25rem;
  position: absolute;
  top: calc(50% - 0.25rem);
  left: 1rem;
}

.device-browser-header-btn-list-btn {
  width: 0.5rem;
  height: 0.5rem;
  background-color: #e7eaf3;
  border-radius: 50%;
}
.device-browser-header-browser-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #677788;
  background-color: #e7eaf3;
  font-size: 0.5rem;
  border-radius: 0.125rem;
}

.device-browser-frame {
  background: #fff;
  box-shadow: 0 2.75rem 5.5rem -3.5rem rgba(189, 197, 209, 0.5),
    0 2rem 4rem -2rem rgba(33, 50, 91, 0.3);
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  overflow: hidden;
}

.device-browser-img {
  max-width: 100%;
  height: auto;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.device-browser-shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  margin-bottom: -2.5rem;
  margin-left: -2.5rem;
  left: 0;
  bottom: 0;
  z-index: -1;

  &.bg-1 {
    background-color: rgba(55, 125, 255, 0.1);
  }
  &.bg-2 {
    background-color: rgba(237, 76, 120, 0.1);
  }
  &.bg-3 {
    background-color: rgba(0, 201, 167, 0.1);
  }
  &.bg-4 {
    background-color: rgba(9, 165, 190, 0.1);
  }
  &.bg-5 {
    background-color: rgba(245, 202, 153, 0.1);
  }
  &.bg-6 {
    background-color: rgba(113, 134, 157, 0.1);
  }
}
</style>
